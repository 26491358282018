<template>
  <div id="videoDialog">
    <el-dialog
      :visible.sync="videoDialog.show"
      :show-close="true"
      width="50%"
      @close="$store.commit('setVideoDialog', { show: false, url: '' })"
    >
      <div class="company">
        <div class="logo">
          <el-image
            :src="require('../../assets/images/circleImg.png')"
            fit="fill"
          ></el-image>
        </div>
         <div class="QRCode">
        <p class="wx-title"><i></i>{{title}}</p>
          <el-image
      style="width: 150px; height: 150px"
      :src="url"
      fit="contain"></el-image>
        <!-- <div class="qrcode-wrap"></div>
        <canvas id="imgQRCode" ref="imgQRCode"></canvas> -->
        <p class="wx-desc"><i></i>微信扫描二维码加入圈子</p>
        <div class="wx-error">
          加入圈子与行业大佬近距离交流
          <!-- 加入圈子 <span class="solve-error">联系我们</span> -->
        </div>

        <div class="ps">
          小程序升级中，敬请期待
        </div>
      </div>
      </div>
     
    </el-dialog>
  </div>
</template>
<script>
import QRCode from "qrcode";
export default {
  data() {
    return {
        url:"",
        title:""
    };
  },
  computed: {
    videoDialog: {
      get() {
        return this.$store.state.videoDialog;
      },
      set(newVal) {},
    },
  },
  watch: {
    videoDialog() {
      if (this.videoDialog.show) {
        this.generateQRCode();
      }
    },
  },
  methods: {
    generateQRCode() {
       
      let self = this;
       console.log(self.videoDialog.item)
      this.title = self.videoDialog.item.title
      this.url = self.videoDialog.item.linkUrl
    //   this.$nextTick(() => {
    //     QRCode.toCanvas(self.$refs.imgQRCode, self.videoDialog.url);
    //   });
    },
  },
};
</script>
<style lang="less" scoped>
#videoDialog {
  .el-dialog {
    border-radius: 11px;
    width: 800px;
    overflow: hidden;

    .el-dialog__header {
      padding: 0!important;
    }
    .el-dialog__body {
      display: flex;
      justify-content: space-between;
      padding: 0;
      .company {
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: row;
        // justify-content: center;
        height: 480px;
        .logo {
        //   width: 100%;
          .el-image {
            height: 500px;
          }
        }
        h6 {
          font-weight: normal;
          font-size: 20px;
          margin: 20px 0;
        }
        .description {
          text-align: center;
          p {
            color: rgb(155, 150, 150);
            font-size: 16px;
            margin-bottom: 5px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .QRCode {
        flex: 1;
        background-color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 500px;
        .wx-title {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          font-size: 20px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.8);
         
        }

        .ps{
          color: #F24945;
          margin-top: 25px;
        }
    .wx-desc{
        display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
         i {
            width: 26px;
            height: 26px;
            background: url(https://www.chuangkit.com/accountcenter/v2/assets/login-sprite.ba948fa9.svg)
              0 0;
            margin-right: 10px;
          }
    }
        canvas {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          width: 184px !important;
          height: 184px !important;
          background: #fff;
          border: 1px solid #eee;
          border-radius: 6px;
          margin: 24px auto;
          padding: 12px;
          box-sizing: border-box;
        }
        .wx-error {
          margin-top: 8px;
          font-size: 12px;
          color: #8693ab;
          .solve-error {
            color: #0773fc;
          }
        }
      }
    }
  }
}
</style>